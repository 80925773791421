import Button from 'components/shared/Button';
import routes from 'data/globals/routes';
import React from 'react';
import styled from 'styled-components';
import { breakpoints } from 'variables';

const ButtonsContainer = styled.section`
   a {
      display: flex;
      flex-flow: column wrap;
      margin-right: 7px;
      ${breakpoints.lg`
         margin-right: 15px;
      `}
   }
   display: flex;
   justify-content: center;
   margin-top: 30px;
   align-items: flex-start;
`;

const HeaderButtons = () => (
   <ButtonsContainer>
      <Button filled={true} link={routes.contact}>
         Wypróbuj za darmo
      </Button>
      <Button linkToHash={'featured'}>Odkrywaj</Button>
   </ButtonsContainer>
);

export default HeaderButtons;
