import AdvantagesSection from 'components/pages/benefits/AdvantagesSection';
import CustomizationSection from 'components/pages/benefits/CustomizationSection';
import PossibilitiesSection from 'components/pages/benefits/PossibilitiesSection';
import PreviewSection from 'components/pages/benefits/PreviewSection';
import TechnologySection from 'components/pages/benefits/TechnologySection';
import HeaderButtons from 'components/shared/HeaderButtons';
import PageHeader from 'components/shared/PageHeader';
import SEO from 'components/shared/SEO';
import React from 'react';

const Benefits = () => {
   const headerText = [
      <strong>Osczędzaj czas i</strong>,
      ' pozbądź się błędów z naszym systemem',
   ];

   return (
      <>
         <SEO title="Korzyści" />
         <PageHeader headerText={headerText}>
            <HeaderButtons />
         </PageHeader>

         <CustomizationSection />
         <PreviewSection />
         <AdvantagesSection />
         <TechnologySection />
         <PossibilitiesSection />
      </>
   );
};

export default Benefits;
