import React, { Fragment } from 'react';
import Container from 'components/shared/Container';

import {
   ListContainer,
   ImageContainer,
   ListItem,
   ListDescription,
   ListText,
} from './ListWithIcons.styled';

const ListWithIcons = ({ listItems }) => {
   return (
      <Container>
         <ListContainer>
            {listItems.map((item, index) => (
               <ListItem key={index}>
                  <ImageContainer>
                     <img src={item.image} alt="icon" />
                  </ImageContainer>

                  <ListDescription>
                     <h4>
                        {item.header.map((text, i) => (
                           <Fragment key={i}>{text}</Fragment>
                        ))}
                     </h4>

                     <ListText>{item.description}</ListText>
                  </ListDescription>
               </ListItem>
            ))}
         </ListContainer>
      </Container>
   );
};

export default ListWithIcons;
