import React from 'react';

import SectionHeader from 'components/shared/SectionHeader';
import ListWithIcons from 'components/pages/benefits/ListWithIcons';

import illustration1 from 'assets/icons/Ilustracja2.svg';
import illustration2 from 'assets/icons/Ilustracja3.svg';
import illustration3 from 'assets/icons/Ilustracja4.svg';

const PossibilitiesSection = () => {
   const sectionText = [<strong>Możliwości</strong>, ' systemu LabControl'];

   const listItems = [
      {
         header: [<strong>Elastyczność</strong>],
         description:
            'Dostosuj szablony i harmonogram do swojej firmy, bez względu na to, czy przeprowadzasz badania czy hodujesz storczyki.',
         image: illustration1,
      },
      {
         header: [<strong>Łatwość</strong>],
         description:
            'Niezliczone testy z klientami i wciąż dopracowywana użyteczność przyczyniają się do ogromnej intuicyjności w obsłudze.',
         image: illustration2,
      },
      {
         header: [<strong>Samodzielność</strong>],
         description:
            'Po otrzymaniu loginu i hasła do systemu możesz od razu zacząć pracę. Nie potrzebujesz żadnej instalacji i wsparcia informatycznego.',
         image: illustration3,
      },
   ];

   return (
      <>
         <SectionHeader sectionText={sectionText} />

         <ListWithIcons listItems={listItems} />
      </>
   );
};

export default PossibilitiesSection;
