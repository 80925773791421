import React, { Fragment } from 'react';
import Container from 'components/shared/Container';
import {
   RowContainer,
   ListContainer,
   ImageContainer,
   ListItem,
   ListDescription,
   ListText,
} from './ListWithImage.styled';

const ListWithImage = ({
   image,
   imageX2,
   headerText,
   listItems,
   reverseRow = false,
   bgColor,
   imgPosition,
}) => {
   return (
      <Container>
         <RowContainer reverseRow={reverseRow}>
            <ListContainer reverseRow={reverseRow}>
               <h3>
                  {headerText.map((text, i) => (
                     <Fragment key={i}>{text}</Fragment>
                  ))}
               </h3>

               {listItems.map((item, index) => (
                  <ListItem key={index}>
                     <div>
                        <img src={item.icon} alt="icon" />
                     </div>

                     <ListDescription>
                        <h4>
                           {item.headerText.map((text, i) => (
                              <Fragment key={i}>{text}</Fragment>
                           ))}
                        </h4>

                        <ListText>{item.description}</ListText>
                     </ListDescription>
                  </ListItem>
               ))}
            </ListContainer>

            <ImageContainer imgPosition={imgPosition} bgColor={bgColor}>
               <img src={image} srcSet={`${image} 1x, ${imageX2} 2x`} alt="" />
            </ImageContainer>
         </RowContainer>
      </Container>
   );
};

export default ListWithImage;
