import React from 'react';

import cost from 'assets/icons/benefits/cost_48.svg';
import forms from 'assets/icons/benefits/forms_48.svg';
import plan from 'assets/icons/benefits/plan_48.svg';
import image from 'assets/images/benefits/photo27.png';
import imageX2 from 'assets/images/benefits/photo27@2x.png';
import ListWithImage from 'components/pages/benefits/ListWithImage';
import { colors } from 'styles/variables';
import Container from 'components/shared/Container';

const CustomizationSection = () => {
   const listHeader = [
      <strong>Dostosuj nasz system</strong>,
      ' do swoich indywidualnych potrzeb',
   ];

   const listItems = [
      {
         headerText: ['Pracuj na ', <strong>cyfrowych formularzach</strong>],
         description:
            'Wydrukowanie i naklejenie kodu kreskowego zajmuje 300 razy mniej czasu* niż wypisanie formularza papierowego. (opracowanie własne)',
         icon: forms,
      },
      {
         headerText: ['Obniżaj ', <strong>koszty</strong>],
         description:
            'Koszt pracownika poprawiającego błędy jest podwójną stratą. Nie bierz jej więcej na siebie.',
         icon: cost,
      },
      {
         headerText: [
            <strong>Oszczędzaj czas</strong>,
            ' na tworzeniu raportów',
         ],
         description:
            'Chcesz skrócić czas przygotowywania raportów z dwóch tygodni do czterech godzin? Zrób to z naszym systemem.',
         icon: plan,
      },
   ];

   return (
      <Container>
         <ListWithImage
            headerText={listHeader}
            listItems={listItems}
            image={image}
            imageX2={imageX2}
            imgPosition="right"
            bgColor={colors.lightBlue}
         />
      </Container>
   );
};

export default CustomizationSection;
