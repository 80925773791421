import styled from 'styled-components';
import { breakpoints } from 'variables';

export const VideoContainer = styled.div`
   margin: 30px 0;
   box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
   cursor: pointer;

   img {
      width: 100%;
   }

   ${breakpoints.lg`
      margin: 70px 0 60px;
   `}
`;
