import styled from 'styled-components';
import { breakpoints } from 'variables';

export const RowContainer = styled.div`
   display: flex;
   flex-direction: column;
   margin-top: 80px;

   ${breakpoints.lg`
      margin-top: 100px;
      flex-direction: ${({ reverseRow }) =>
         reverseRow ? 'row-reverse' : 'row'};
   `};
`;

export const ListContainer = styled.div`
   display: block;

   h3 {
      font-weight: 300;
      margin-bottom: 40px;

      strong {
         font-weight: 700;
      }
   }

   ${breakpoints.lg`
      ${({ reverseRow }) =>
         reverseRow ? 'padding-left: 70px' : 'padding-right: 100px'};
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 50%;
   `}
`;

export const ImageContainer = styled.div`
   background-color: ${(props) => props.bgColor};
   height: 400px;
   border-radius: 12px;
   display: flex;
   align-items: flex-end;
   justify-content: center;

   img {
      margin-left: ${(props) => props.imgPosition === 'right' && 'auto'};
      max-height: 350px;
      height: auto;
   }
   ${breakpoints.md`
      flex: 50%;
      height: 700px;
      img {
         height: auto;
         max-height: 670px;
      }
   `}
`;

export const ListItem = styled.div`
   display: flex;
   margin-bottom: 40px;
`;

export const ListDescription = styled.div`
   display: block;
   font-weight: 300;

   h4 {
      font-weight: 300;
      strong {
         font-weight: 700;
      }
   }

   padding-left: 20px;
`;

export const ListText = styled.p`
   margin: 10px 0 0 0;
`;
