import React from 'react';

import SectionHeader from 'components/shared/SectionHeader';
import VideoPlayer from 'components/pages/benefits/VideoPlayer';

const PreviewSection = () => {
   const sectionText = [
      <strong>Tworzysz, edytujesz i usuwasz</strong>,
      ' cyfrowe formularze bez wsparcia IT',
   ];

   return (
      <>
         <SectionHeader sectionText={sectionText} />

         <VideoPlayer />
      </>
   );
};

export default PreviewSection;
