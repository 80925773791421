import React from 'react';

import SectionHeader from 'components/shared/SectionHeader';
import ListWithIcons from 'components/pages/benefits/ListWithIcons';

import illustration1 from 'assets/icons/Ilustracja2.svg';
import illustration2 from 'assets/icons/Ilustracja3.svg';
import illustration3 from 'assets/icons/Ilustracja4.svg';

const AdvantagesSection = () => {
   const sectionText = [<strong>Zalety</strong>, ' systemu LabControl'];

   const listItems = [
      {
         header: [
            <strong>Oszczędność czasu</strong>,
            ' dzięki szybkiej wymianie informacji',
         ],
         description:
            'Dane, wyniki, status realizowanych zadań aktualizowane w czasie rzeczywistym.',
         image: illustration1,
      },
      {
         header: [
            <strong>Dostęp z różnych urządzeń</strong>,
            ' w tym  komputerów i tabletów',
         ],
         description:
            'System dostosowany jest do pracy poza firmą - wystarczy urządzenie i dostęp do internetu.',
         image: illustration2,
      },
      {
         header: [
            'Relatywnie ',
            <strong>niski koszt wdrożenia</strong>,
            ' systemu',
         ],
         description:
            'Koszt licencji to nawet 33 zł dziennie, dopasuj pakiet do swojej firmy i nie przepłacaj.',
         image: illustration3,
      },
   ];

   return (
      <>
         <SectionHeader sectionText={sectionText} />
         <ListWithIcons listItems={listItems} />
      </>
   );
};

export default AdvantagesSection;
