import React from 'react';

import ListWithImage from 'components/pages/benefits/ListWithImage';
import { TechnologyContainer } from './TechnologySection.styled';

import image from 'assets/images/benefits/photo28.png';
import imageX2 from 'assets/images/benefits/photo28@2x.png';
import szablon from 'assets/icons/benefits/szablon_48.svg';
import history from 'assets/icons/benefits/history_48.svg';
import plan from 'assets/icons/benefits/plan_48.svg';
import { colors } from 'styles/variables';

const TechnologySection = () => {
   const listHeader = [
      'Wspieraj prace personelu ',
      <strong>dzięki nowej technologii</strong>,
   ];

   const listItems = [
      {
         headerText: [
            <strong>Wyeliminuj papierowe notatki</strong>,
            ' "na wszelki wypadek"',
         ],
         description:
            'Zapewnij pracownikom poczucie bezpieczeństwa. Każdy pracownik w formularzu zadania będzie widział historię wyników nawet do sześciu okresów wstecz.',
         icon: szablon,
      },
      {
         headerText: [
            'Pomóż pracownikom ',
            <strong>kontrolować zadania na bieżąco</strong>,
         ],
         description:
            'Jeden rzut oka wystarczy, by skontrolować status wykonywanych zadań. Specjalne oznakowanie błędów lub opóźnień pozwoli uniknąć poważnych konsekwencji.',
         icon: history,
      },
      {
         headerText: [
            'Ułatw pracownikom ',
            <strong>bycie bezbłędnym</strong>,
            ' dzięki systemowi',
         ],
         description:
            'Pola formularzy zadań stworzone tak, by nie można było pominąć żadnej ważnej czynności czy adnotacji w wyniku.',
         icon: plan,
      },
   ];

   return (
      <TechnologyContainer>
         <ListWithImage
            image={image}
            imageX2={imageX2}
            headerText={listHeader}
            listItems={listItems}
            reverseRow={true}
            bgColor={colors.lightViolet}
         />
      </TechnologyContainer>
   );
};

export default TechnologySection;
